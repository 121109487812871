// import ReactGoogleLogin from "react-google-login";
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../services/redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { clearError } from '../../services/redux/modules/user';
import { decodeUser } from '../../services/mapping';
import { googleLogin } from '../../lib/social';

export default function GoogleLogin(props) {
  const { google, user } = useSelector(
    (state) => ({
      google: state.ui.social.google,
      user: state.user,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const isLoading = user.loading && user.loading.indexOf('google-login') > -1;

  const googleLoginClick = async () => {
    try {
      const response = await googleLogin(google);
      await responseGoogle(response);
    } catch (e) {
      dispatch(actions.user.showError(e.details));
    }
  };

  const responseGoogle = async (response) => {
    dispatch(clearError());
    if (response) {
      if (props.onLogin) {
        props.onLogin(response);
      }

      if (response.accessToken) {
        const data = await dispatch(
          actions.user.googleLogin(response.accessToken)
        );
        if (data && data.access_token) {
          const user = decodeUser(data.access_token);
          const socialUser = await dispatch(actions.user.getUser(null, user));
          if (socialUser.usertype) {
            const nav = await dispatch(
              actions.navigation.set(socialUser.usertype)
            );
          }

          // if (socialUser && !socialUser.usertype) {
          //dispatch(actions.user.register(user));
          // }
        } else {
        }
      } else if (!response || response.error === 'popup_closed_by_user') {
        dispatch(actions.user.showError('Could not complete Login'));
      }
      /**
       response:{
       profileObj:{
         email: "delsanic@gmail.com"
         familyName: "sanic"
         givenName: "del"
         googleId: "114012659342847965475"
         imageUrl: "https://lh3.googleusercontent.com/a-/AOh14GgXlnvcszXUhyrdcns8DUQEtaOd45a2cQPYXCa9=s96-c"
         name: "del sanic"
       }
     }
       */
    } else {
      dispatch(actions.user.showError('Could not complete Login'));
      if (props.onFailure) {
        props.onFailure(response);
      }
    }
  };

  const onClick = () => {};

  return (
    // <ReactGoogleLogin
    //   clientId={google.clientId}
    //   buttonText="Login"
    //   render={renderProps => (
    <button
      onClick={googleLoginClick}
      className="w-full btn btn-default btn-flazt border-1 border-black rounded-20px btn-icon transition duration-500 btn-outlined-google text-black hover:bg-gray-100"
      style={{ fontWeight: '600' }}
    >
      {/*<i className="icon-social-google font-bold ml-0 ltr:mr-2"/>*/}
      <img src="/logos/google.svg" className="w-5 mr-2" />
      {/*<span>Continue with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
      <span className="mr-2">Continue with Google</span>
      {isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin={true} /> : ''}
    </button>
    //   )}
    //   onSuccess={responseGoogle}
    //   onFailure={responseGoogle}
    // />
  );
}

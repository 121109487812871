import React from 'react';
import TextLoop from 'react-text-loop';

const MobileBanner = ({}) => {
  return (
    <div>
      <div
        className="font-black text-xl m-auto text-32px lg:hidden mb-5 -mt-6 relative z-1"
        style={{ width: '222px' }}
      >
        Get
        <span className="ml-2 text-brand-base">
          <TextLoop>
            <span>Paid</span>
            <span>Collabs</span>
            <span>Products</span>
            <span>Viral</span>
            <span>Fame</span>
            <span>#infloso</span>
          </TextLoop>
        </span>
      </div>
    </div>
  );
};

export default MobileBanner;

import React, { useState } from 'react';
import Link from 'next/link';
import { TextInput } from '../inputs/TextInput';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../services/redux/actions';
import Button from '../action/Button';
import { toast } from 'react-toastify';

const LoginForm = ({ data, error, setData, onDataSubmit }) => {
  const { ui } = useSelector(
    (state) => ({
      ui: state.ui,
    }),
    shallowEqual
  );

  const [otpSent, setOtpSent] = useState(false);

  const [withPassword, setWithPassword] = useState(false);

  const dispatch = useDispatch();

  const goWithPassword = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.user.clearError());

    setWithPassword(true);
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isMobile = data.email.indexOf('@') === -1;

    // if (isMobile) {
    //   toast.success("We're upgrading! SMS OTPs are not available right now.");
    //   return;
    // }

    dispatch(actions.user.clearError());
    const resp = await dispatch(actions.user.sendOTP(data.email));
    if (resp && resp.error_code !== '400') {
      setOtpSent(true);

      setData((data) => ({ ...data, isOTP: true, isMobile }));
    }
  };

  return (
    <div className="relative">
      <form onSubmit={onDataSubmit}>
        <div className="text-gray-700">
          {/*<p className="text-sm mb-3">Email or Username</p>*/}
          <div className="flex relative mb-4">
            <TextInput
              showError={false}
              errorSpace={false}
              type="text"
              label="Email, Phone or Username"
              value={data.email}
              allowMobile={true}
              onChange={(value) => setData({ ...data, email: value })}
              error={
                error === 'Email, Phone or Username is required' ||
                error === 'Email or Phone is required'
                  ? error
                  : null
              }
            />
            {/*<input*/}
            {/*  type="text"*/}
            {/*  className="border-gray-300 relative border-b-2 focus:border-b-2 text-sm md:text-md text-gray-800 w-full  outline-none focus:border-b-2 focus:border-brand-base"*/}
            {/*  value={data.email}*/}
            {/*  onChange={(e) => setData({ ...data, email: e.target.value })}*/}
            {/*/>*/}
            {/*<FontAwesomeIcon*/}
            {/*  className="text-xl absolute right-0"*/}
            {/*  icon={faEnvelope}*/}
            {/*/>*/}
          </div>
        </div>
        {otpSent || withPassword ? (
          <div className="text-gray-700">
            {/*<p className="text-sm mb-3">Password</p>*/}
            <div className="flex relative mb-4">
              <TextInput
                showError={false}
                errorSpace={false}
                type="password"
                label={otpSent ? 'Enter OTP' : 'Password'}
                value={data.password}
                onChange={(value) => setData({ ...data, password: value })}
                error={error === 'Password is required' ? error : null}
              />
              {/*<input*/}
              {/*  type="password"*/}
              {/*  className="border-gray-300 relative border-b-2 focus:border-b-2 text-sm md:text-md text-gray-800 w-full outline-none focus:border-b-2 focus:border-brand-base"*/}
              {/*  value={data.password}*/}
              {/*  onChange={(e) => setData({ ...data, password: e.target.value })}*/}
              {/*/>*/}
              {/*<FontAwesomeIcon*/}
              {/*  className="text-xl absolute right-0"*/}
              {/*  icon={faLock}*/}
              {/*/>*/}

              {withPassword && (
                <div
                  className="mb-2 overflow-auto absolute right-0"
                  style={{ top: '3px', fontSize: '14px' }}
                >
                  {ui.social_login && (
                    <p className="text-brand-base font-medium float-right">
                      <Link href="/forgot-password">Forgot Password</Link>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {!withPassword && !otpSent && (
          <div className="overflow-auto">
            {ui.social_login && (
              <div className="font-bold text-brand-base font-medium">
                <p
                  className="cursor-pointer border-1 border-brand-base hover:bg-gray-100 transition whitespace-no-wrap  duration-500 rounded-20px px-2 py-2 text-center"
                  onClick={sendOTP}
                >
                  Login with OTP
                </p>
              </div>
              // <Button type="secondary" onClick={sendOTP} className="w-full">
              //   Login with OTP
              // </Button>
            )}
          </div>
        )}
        {otpSent && (
          <div className="overflow-auto">
            {ui.social_login && (
              <p className="font-medium">
                <span>OTP sent to {data.email}. </span>
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={sendOTP}
                >
                  Resend
                </span>
              </p>
            )}
          </div>
        )}
        <div className="">
          {!withPassword && !otpSent && (
            <div className="mt-6 flex-1">
              {/*<button*/}
              {/*  className="font-bold w-full h-50px bg-brand-base text-white py-2 rounded-10px"*/}
              {/*>*/}
              {/*  Login with Password*/}
              {/*</button>*/}
              <Button className="w-full" onClick={goWithPassword}>
                Login with Password
              </Button>
            </div>
          )}
          {/*{!withPassword && !otpSent && (*/}
          {/*  <div className="mt-2 flex-initial">*/}
          {/*    <button*/}
          {/*      className="w-full bg-brand-base text-white py-2 rounded-md"*/}
          {/*      onClick={sendOTP}*/}
          {/*    >*/}
          {/*      Login with OTP*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {otpSent || withPassword ? (
          <div className="mt-2">
            <Button buttonType="submit" className="w-full">
              Continue
            </Button>
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default LoginForm;

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Link from 'next/link';

const Logo = (props) => {
  const { name, logo } = useSelector(
    (state) => ({
      name: state.ui.name,
      logo: state.ui.logo,
    }),
    shallowEqual
  );

  return (
    <div className="logo flex flex-row items-center font-bold text-lg tracking-wider justify-center">
      <Link href="/">
        <a className="flex flex-row items-center justify-start children-x-1">
          <img
            src={logo || '/64x64.png'}
            alt="image"
            className="h-32 rounded"
          />
          {!props.hideName && (
            <span className="ml-2 hidden lg:block">{name}</span>
          )}
        </a>
      </Link>
    </div>
  );
};

export default Logo;

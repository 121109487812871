import FacebookLogin from '../../social/FacebookLogin';
import GoogleLogin from '../../social/GoogleLogin';
import React from 'react';

export default function SocialLogin(props) {
  return (
    <div className="flex w-full flex-col gap-y-6">
      <div className="flex-1">
        <GoogleLogin />
      </div>
      <div className="flex-1 mt-1">
        <FacebookLogin />
      </div>
    </div>
  );
}

import React from 'react';
// import ReactFacebookLogin from "react-facebook-login";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../services/redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { clearError, showError } from '../../services/redux/modules/user';
import { decodeUser } from '../../services/mapping';
import { fbLogin } from '../../lib/social';

export default function FacebookLogin(props) {
  const { facebook, user } = useSelector(
    (state) => ({
      facebook: state.ui.social.facebook,
      user: state.user,
    }),
    shallowEqual
  );
  const isLoading = user.loading && user.loading.indexOf('facebook-login') > -1;
  const dispatch = useDispatch();

  const responseFacebook = (response) => {};

  const fbLoginClick = async () => {
    const api_response = await fbLogin(facebook);
    const accessToken =
      api_response &&
      api_response.authResponse &&
      api_response.authResponse.accessToken;

    if (accessToken) {
      if (props.onLogin) {
        props.onLogin(api_response);
      }
      const data = await dispatch(actions.user.facebookLogin(accessToken));
      if (data && data.access_token) {
        const user = decodeUser(data.access_token);
        const socialUser = await dispatch(actions.user.getUser(null, user));
        if (socialUser.usertype) {
          const nav = await dispatch(
            actions.navigation.set(socialUser.usertype)
          );
        }
      } else {
        dispatch(actions.user.showError('Could not complete Login'));
      }
    } else {
      dispatch(actions.user.showError('Could not complete Login'));

      if (props.onFailure) {
        props.onFailure(api_response);
      }
    }
  };

  // const fbLogin_old = () => {
  //   dispatch(clearError());
  //
  //   FB.login(function (response) {
  //     if (response.authResponse) {
  //       FB.api('/me?fields=id,name,first_name,last_name,picture,email', async function (api_response) {
  //
  //         api_response.accessToken = response.authResponse.accessToken;
  //         if (props.onLogin) {
  //           props.onLogin(api_response);
  //         }
  //         const data = await dispatch(actions.user.facebookLogin(response.authResponse.accessToken));
  //         if(data && data.access_token) {
  //           const user = decodeUser(data.access_token);
  //           const socialUser = await dispatch(actions.user.getUser(user));
  //           if(socialUser.usertype) {
  //             const nav = await dispatch(actions.navigation.set(socialUser.usertype));
  //           }
  //         }else{
  //           dispatch(actions.user.showError('Could not complete Login'))
  //         }
  //
  //         /**
  //          email: "delsanic@gmail.com"
  //          first_name: "Del"
  //          id: "3147430372154022"
  //          last_name: "Sanic"
  //          name: "Del Sanic"
  //          picture:{
  //            data:{
  //              height: 50
  //              is_silhouette: false
  //              url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3147430372154022&height=50&width=50&ext=1625251027&hash=AeR9q92DfOSytwNtV1Q"
  //              width: 50
  //            }
  //          }
  //          */
  //       });
  //     } else {
  //
  //       dispatch(actions.user.showError('Could not complete Login'))
  //
  //       if (props.onFailure) {
  //         props.onFailure(response);
  //       }
  //     }
  //   }, {scope: 'email,public_profile'});
  // }
  //

  return (
    <>
      {/*<div className="hidden">*/}
      {/*  <ReactFacebookLogin appId={facebook.appId}*/}
      {/*                      version = "11.0"*/}
      {/*                      callback={responseFacebook}*/}
      {/*                      fields="id,cover,name,first_name,last_name,age_range,link,gender,locale,email,picture,timezone,update_time,verified"*/}
      {/*                      scope="public_profile"*/}
      {/*  />*/}
      {/*</div>*/}
      <button
        onClick={fbLoginClick}
        className="w-full btn btn-default btn-flatx border-1 border-black rounded-20px btn-icon btn-outlined-facebook transition duration-500 text-black hover:bg-gray-100"
        style={{ fontWeight: '600' }}
      >
        {/*<i className="icon-social-facebook font-bold ltr:mr-2 text-facebook"/>*/}
        <img src="/logos/facebook2.svg" className="w-5 mr-2 float-left" />
        <span className="mr-2">Continue with Facebook</span>
        {isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin={true} /> : ''}
      </button>
    </>
  );
}

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Link from 'next/link';

const LoginBanner = ({ children, img = 'login.svg', title = 'Infloso' }) => {
  const { ui } = useSelector(
    (state) => ({
      ui: state.ui,
    }),
    shallowEqual
  );

  return (
    <div
      className="w-6/12 h-full hidden flex items-center justify-center flex-col lg:block md:rounded-l-10px"
      style={{ backgroundColor: '#FAFAFB' }}
    >
      <div className="flexz justify-between w-full mx-auto pt-4 px-8">
        <div className="flex flex-row">
          <div className="flex-1"></div>
          <Link href="/">
            <a href="/">
              <img src={ui.logo} alt="logo" className="w-20 cursor-pointer" />
            </a>
          </Link>
          <div className="flex-1"></div>
          {/*<p className="self-center ml-4 font-extrabold text-3xl">{ui.name}</p>*/}
        </div>
        {ui.social_login && <div>{children}</div>}
      </div>
      <div
        className="py-10 px-20 mx-auto w-full"
        style={{ minHeight: '280px' }}
      >
        <img
          src={`/images/illustration/${img}`}
          alt="social media marketing"
          className="mx-auto"
        />
      </div>
      <div className="text-center font-bold text-28px mb-10">
        <div style={{ width: '335px', margin: 'auto', lineHeight: '38px' }}>
          {title}
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
